<template>
  <div id="user-view" class="my-0 my-md-12">
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card v-if="pageLoading"> </v-card>
        <v-card v-else elevation="3" :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : '0'">
          <v-card-title class="grey">
            <v-chip class="mr-3" outlined> ID: {{ userData.id }} </v-chip>
            <v-chip outlined> Sıra: {{ userData.sira }} </v-chip>
            <v-spacer></v-spacer>
            <v-chip :color="userData.aktif === '1' ? 'success' : 'warning'" link outlined>
              {{ userData.aktif === '1' ? 'Aktif' : 'Pasif' }}
            </v-chip>
          </v-card-title>
          <v-card-title class="pt-0 grey">
            {{ userData.baslik }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row align="start" justify="center">
              <v-col cols="12" md="4">
                <v-card height="320" color="primary" class="d-flex align-center justify-center">
                  <v-img
                    v-if="userData.pictures"
                    class="py-6 my-6"
                    contain
                    height="300"
                    :src="'https://portalapi.mesan.com.tr/' + userData.pictures.dresim[0].dosya_adi"
                  ></v-img>
                  <v-icon v-else :size="90">
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <v-list color="transparent" three-line>
                  <v-list-item v-if="userData.kategori">
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-medium white--text">
                        Ürün Kategorisi
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-body-2 text--secondary">
                        {{ userData.kategori }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="userData.kategori" />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-medium white--text">
                        Ürün Açıklama
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-body-2 text--secondary">
                        {{ userData.kisa_aciklama }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-medium white--text"> Ürün Kodu </v-list-item-subtitle>
                      <v-list-item-title class="text-body-1 text--secondary">
                        {{ userData.urun_kodu }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-medium white--text"> Fabrika </v-list-item-subtitle>
                      <v-list-item-title class="text-body-1 text--secondary">
                        <v-chip
                          v-for="(plant, i) in plants(userData.plant)"
                          :key="`${i}-plant`"
                          class="mr-3"
                          color="secondary"
                          label
                        >
                          <span class="text--secondary">{{ statusFind('fabrika', plant, 'text') }}</span>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-medium white--text">
                            Min. Satış Adet
                          </v-list-item-subtitle>
                          <v-list-item-title class="text-body-1 text--secondary" style="overflow: initial">
                            <v-chip label>
                              <span v-if="userData.min_satis_adeti === '0'">Sınır Yok</span>
                              <span v-else>{{ userData.min_satis_adeti }} Adet</span>
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-medium white--text">
                            Max. Satış Adet
                          </v-list-item-subtitle>
                          <v-list-item-title class="text-body-1 text--secondary" style="overflow: initial">
                            <v-chip label>
                              <span v-if="userData.max_satis_adeti === '0'">Sınır Yok</span>
                              <span v-else>{{ userData.max_satis_adeti }} Adet</span>
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-divider />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-medium white--text">
                            Satış Fiyati
                          </v-list-item-subtitle>
                          <v-list-item-title class="text-body-1 text--secondary" style="overflow: initial">
                            <v-chip label>
                              <span v-if="userData.satis_fiyati === '0.00'">Belirtilmemiş</span>
                              <span v-else>{{ userData.satis_fiyati | currency }}</span>
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-medium white--text">
                            Piyasa Fiyati
                          </v-list-item-subtitle>
                          <v-list-item-title class="text-body-1 text--secondary" style="overflow: initial">
                            <v-chip label>
                              <span v-if="userData.piyasa_fiyati === '0.00'">Belirtilmemiş</span>
                              <span v-else>{{ userData.piyasa_fiyati | currency }}</span>
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-divider />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <span class="font-weight-medium white--text"> Para Birimi</span>
                          </v-list-item-subtitle>

                          <v-list-item-title class="text-body-1 text--secondary">
                            <v-chip color="success" label outlined>
                              {{ userData.para_birimi }}
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <span class="font-weight-medium white--text"> KDV Oran</span>
                          </v-list-item-subtitle>

                          <v-list-item-title class="text-body-1 text--secondary">
                            <v-chip color="success" label outlined>
                              {{ statusFind('kdv', userData.kdv_orani, 'text') }}
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-divider />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-medium white--text"> Birim </v-list-item-subtitle>
                          <v-list-item-title class="text-body-1 text--secondary">
                            <v-chip color="warning" label outlined>
                              {{ statusFind('birim', userData.birim, 'text') }}
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <span class="font-weight-medium white--text"> Paket Adet</span>
                          </v-list-item-subtitle>

                          <v-list-item-title class="text-body-1 text--secondary">
                            <v-chip color="warning" label outlined>
                              {{ userData.birim_ritmi }}
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { mdiCameraOff } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import eventStoreModule from '../eventStoreModule'

export default {
  setup() {
    const EVENT_APP_STORE_MODULE_NAME = 'app-event'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    const { router } = useRouter()
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const userData = ref({})
    const loading = ref(true)
    const pageLoading = ref(true)
    const plants = val => {
      let result

      if (val) {
        result = val.split(',')
      } else {
        result = []
      }

      return result
    }
    onMounted(() => {
      if (!router.currentRoute.params.data) {
        router.push({ name: 'event-list' })
      }
      userData.value = router.currentRoute.params.data
      pageLoading.value = false
    })

    // ui

    return {
      statusFind,
      plants,
      userData,
      loading,
      pageLoading,
      icons: { mdiCameraOff },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
